<div class="dialog-title">
  <h1 class="text-2xl">Totals</h1>

  <button mat-icon-button mat-dialog-close>
    <mat-icon fontSet="icon-[solar--close-circle-linear]"></mat-icon>
  </button>
</div>

<div class="dialog-content">
  <mat-list>
    <ng-container>
      @for (item of state.uniformTotals(); track $index) {
        <mat-list-item>
          <div class="flex justify-between items-center">
            <h3>{{ item().name }}</h3>
            <label>{{ item().total || 0 }}</label>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      }
    </ng-container>
  </mat-list>
</div>

<div class="dialog-actions">
  <button mat-raised-button mat-dialog-close class="!bg-error">Cancel</button>

  <button
    cdkFocusInitial
    mat-raised-button
    class="!bg-success"
    (click)="resolver.exportTotals()"
  >
    Export
  </button>
</div>
