/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'api';
import { LoadingService } from 'loading';
import { NotificationService } from 'notification';
import { PusherEvent, WebsocketService } from 'websocket';

import { PinState } from '../../pin/store/pin.state';
import { UniformState } from './uniform.state';
import { UniformQueryResult } from './uniform.type';

import { UiConfirmComponent } from 'components/ui/confirm/src';
import { map } from 'rxjs';
import { Staff } from 'staff-preview';
import { TotalsComponent } from '../totals/totals.component';

@Injectable({
  providedIn: 'root',
})
export class UniformResolver {
  public readonly websocketService = inject(WebsocketService);
  public readonly notificationService = inject(NotificationService);
  public readonly loadingService = inject(LoadingService);
  public readonly apiService = inject(ApiService);
  public readonly state = inject(UniformState);
  public readonly pinState = inject(PinState);
  public readonly dialog = inject(MatDialog);
  public readonly router = inject(Router);

  public getStaff() {
    const model = this.pinState.model().model;

    this.state.staff.set({
      data: this.pinState.staff().data() as Staff,
      type: model === 'User' ? 'stambridge' : 'contractor',
    });
  }

  public getUniforms() {
    this.loadingService.show();
    this.state.items.set([]);

    this.websocketService
      .query<UniformQueryResult>(this.state.uniformQueries())
      .subscribe(({ event, data }) => {
        this.loadingService.hide();

        if (event === PusherEvent.ERROR) {
          this.notificationService.error(data.message as string);
          return;
        }

        this.state.items.set(
          data[this.state.uniformModel].map((item) => {
            return signal({
              id: item.id,
              name: item.name,
              total: item.link?.total || 0,
              lost: item.link?.lost || 0,
              comments: item.link?.comments || '',
              permanent: item.link?.permanent || false,
              link_id: item.link?.id as number,
              issue_number: item.link?.issue_number as number,
            });
          }),
        );

        const linkItem = data[this.state.uniformModel].some(
          (item) => !item.link,
        );

        if (linkItem) {
          this.updateUniforms().subscribe(() => this.getUniforms());
        }
      });
  }

  public getUniformTotal() {
    this.loadingService.show();
    this.state.uniformTotals.set([]);

    this.websocketService
      .query<UniformQueryResult>(this.state.uniformTotalQueries())
      .subscribe(({ event, data }) => {
        this.loadingService.hide();

        if (event === PusherEvent.ERROR) {
          this.notificationService.error(data.message as string);
          return;
        }

        this.state.uniformTotals.set(
          data[this.state.uniformTotalModel].map((item) =>
            signal({
              id: item.id,
              name: item.name,
              total: Number(item.total || 0),
            }),
          ),
        );
      });
  }

  public updateUniforms() {
    this.loadingService.show();

    return this.websocketService.mutation(this.state.uniformMutation()).pipe(
      map(({ event, data }) => {
        this.loadingService.hide();

        if (event === PusherEvent.ERROR) {
          this.notificationService.error(data.message as string);
          return;
        }
      }),
    );
  }

  submit() {
    const dialog = this.dialog.open(UiConfirmComponent, {
      width: '400px',
      autoFocus: false,
      data: {
        title: 'Submit',
        message: 'Are you sure you want to submit?',
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.updateUniforms().subscribe(() => {
          this.backToPin();
        });
      }
    });
  }

  returnAll() {
    const dialog = this.dialog.open(UiConfirmComponent, {
      width: '400px',
      autoFocus: false,
      data: {
        title: 'Return All',
        message: 'Are you sure you want to return all?',
        options: [{ name: 'Unassign QR', completed: false }],
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (!result) return;

      this.state.items().forEach((item) => {
        item.update((data) => ({ ...data, total: 0, permanent: false }));
      });

      const options = result?.options;
      if (options) {
        //find option unassignQR
        const unassignQR = options.find(
          (option: any) => option.name === 'Unassign QR',
        );
        //find qrId from staff
        const qrId = this.pinState.staff().data()!.qr?.id;
        if (unassignQR && unassignQR.completed && qrId) {
          this.assignQr(qrId).subscribe();
        }
      }

      this.updateUniforms().subscribe(() => {
        this.backToPin();
      });

    });
  }

  assignQr(qrId: number) {
    return this.websocketService.mutation({
      StaffQr: {
        method: 'assignToStaff',
        data: {
          [qrId]: null,
        },
      },
    });
  }

  openTotals() {
    this.getUniformTotal();

    this.dialog.open(TotalsComponent, {
      width: '600px',
      autoFocus: false,
    });
  }

  exportTotals() {
    const dialog = this.dialog.open(UiConfirmComponent, {
      width: '400px',
      autoFocus: false,
      data: {
        title: 'Export Totals?',
      },
    });

    dialog.afterClosed().subscribe(async (result) => {
      if (!result) return;

      this.loadingService.show();

      try {
        await this.apiService.downloadFile(
          'exportUniform/job/' + this.pinState.job()?.id,
          'UniformExport.csv',
        );
      } catch (error) {
        this.notificationService.error('Failed to export totals');
      }

      this.loadingService.hide();
    });
  }

  public seeMore() {
    this.state.more.set(!this.state.more());
  }

  public backToPin() {
    this.router.navigate(['/pin']);
    this.pinState.staff().data.set(undefined);
  }

  public hasPin(redirectTo: string) {
    if (!this.pinState.staff().data()) {
      this.router.navigateByUrl(redirectTo);

      return false;
    }

    return true;
  }
}
