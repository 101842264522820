<div class="dialog-title" data-test="dialog-confirm">
  <h1 class="text-2xl">{{ title }}</h1>

  <button mat-icon-button mat-dialog-close class="-mr-[12px]">
    <mat-icon fontSet="icon-[solar--close-circle-linear]"></mat-icon>
  </button>
</div>

<div class="dialog-content">
  <p class="text-lg" *ngIf="!description">{{ message }}</p>

  <div>
    <p>{{ description }}</p>
    @for (option of options(); track option; let i = $index) {
      <mat-checkbox (change)="update($event.checked, i)">
        {{ option.name }}
      </mat-checkbox>
    }
  </div>
</div>

<div class="dialog-actions">
  <button
    class="!bg-error"
    *ngIf="!hideCancel"
    mat-raised-button
    mat-dialog-close
    [mat-dialog-close]="false"
  >
    {{ labelWarn }}
  </button>
  <button
    class="!bg-success"
    mat-raised-button
    (click)="closeDialog()"
    data-test="dialog-submit"
  >
    {{ labelPrimary }}
  </button>
</div>
