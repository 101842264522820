import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Task, UiConfirmData } from './ui-confirm.type';
@Component({
  selector: 'group-ui-confirm',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatToolbarModule,
    MatIconModule,
    MatCheckboxModule,
  ],
  templateUrl: './ui-confirm.component.html',
  styleUrls: ['./ui-confirm.component.scss'],
})
export class UiConfirmComponent implements OnInit {
  public title: string;
  public message: string;
  public description: string;
  public labelWarn: string;
  public labelPrimary: string;
  public hideCancel: boolean = false;

  readonly options = signal<Task[]>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UiConfirmData,
    private dialogRef: MatDialogRef<UiConfirmComponent>,
  ) {
    this.title = '';
    this.message = '';
    this.description = '';
    this.labelWarn = '';
    this.labelPrimary = '';
  }

  ngOnInit() {
    const data = this.data || {};
    this.title = data.title || 'Confirm Your Action';
    this.message = data.message;
    this.description = data.description;
    this.labelWarn = data.labelWarn || 'Cancel';
    this.labelPrimary = data.labelPrimary || 'Confirm';
    this.hideCancel = data.hideCancel;
    this.options.set(data.options);
  }

  update(completed: boolean, index?: number) {
    this.options.update((options) => {
      if (index !== undefined) {
        options[index].completed = completed;
      }
      return options;
    });
  }

  closeDialog() {
    if (this.options()?.length > 0) {
      this.dialogRef.close({
        options: this.options(),
      });
    } else {
      this.dialogRef.close(true);
    }
  }
}
