import { QueryMultiple } from 'websocket';
import { UniformMutation, UniformQuery } from './uniform.type';

export const UNIFORM_QUERY: UniformQuery = {
  model: 'Uniform',
  query: (data) => ({
    fields: ['uniform.id', 'uniform.name'],
    where: {
      position: {
        value: { job_id: data.job_id },
        operator: 'scope',
      },
    },
    with: [
      {
        relation: 'link',
        queries: {
          where: {
            job_id: data.job_id,
            staff_id: data.staff_id,
            staff_type: data.staff_type,
          },
        },
      },
    ],
  }),
};

export const UNIFORM_MUTATION: UniformMutation = {
  controller: 'Uniform',
  data: (data) => ({
    method: 'updateUniforms',
    data,
  }),
};

export const UNIFORM_TOTAL_QUERY: UniformQuery = {
  model: 'Uniform',
  query: (data) => ({
    fields: ['id', 'name'],
    withSum: [
      {
        relation: 'links as total',
        field: 'total',
        queries: {
          where: {
            job_id: data.job_id,
          },
        },
      },
    ],
  }),
};

export const UNIFORM_QUERY_ADMIN = {
  model: 'Uniform',
  query: {
    fields: ['uniform.id', 'uniform.name'],
  } as QueryMultiple,
};
