import {
  Injectable,
  WritableSignal,
  computed,
  inject,
  signal,
} from '@angular/core';
import { WebsocketQuery } from 'websocket';
import { PinState } from '../../pin/store/pin.state';
import {
  UNIFORM_MUTATION,
  UNIFORM_QUERY,
  UNIFORM_QUERY_ADMIN,
  UNIFORM_TOTAL_QUERY,
} from './uniform.query';
import { UniformItem, UniformStaff, UniformTotalItem } from './uniform.type';

@Injectable({
  providedIn: 'root',
})
export class UniformState {
  public readonly pinState = inject(PinState);
  public readonly staff = signal<UniformStaff | undefined>(undefined);
  public readonly items = signal<UniformItem>([]);
  public readonly uniformTotals = signal<UniformTotalItem>([]);
  public readonly more = signal<boolean>(false);

  public readonly uniformQueries = computed(() => {
    const queries = UNIFORM_QUERY.query({
      job_id: this.pinState.job()?.id,
      staff_id: this.staff()?.data.id,
      staff_type: this.staff()?.type,
    });

    return { [UNIFORM_QUERY.model]: queries };
  });

  public readonly uniformTotalQueries = computed(() => {
    const queries = UNIFORM_TOTAL_QUERY.query({
      job_id: this.pinState.job()?.id,
    });

    return { [UNIFORM_TOTAL_QUERY.model]: queries };
  });

  public readonly model = computed(() => {
    return this.items().map((item) => ({
      id: item().id,
      job_id: this.pinState.job()?.id,
      staff_id: this.staff()?.data.id,
      staff_type: this.staff()?.type,
      total: item().total,
      lost: item().lost,
      comment: item().comments,
      permanent: item().permanent,
    }));
  });

  public readonly uniformMutation = computed(() => ({
    [UNIFORM_MUTATION.controller]: UNIFORM_MUTATION.data(this.model()),
  }));

  get uniformModel() {
    return UNIFORM_QUERY.model;
  }

  get staffPreview() {
    return this.staff as WritableSignal<UniformStaff>;
  }

  get uniformTotalModel() {
    return UNIFORM_TOTAL_QUERY.model;
  }

  public readonly uniformModelAdmin = new WebsocketQuery(
    UNIFORM_QUERY_ADMIN.model,
    UNIFORM_QUERY_ADMIN.query.fields,
  );

  public jobUniformQueries() {
    const query = this.uniformModelAdmin.clone();

    query.setCondition('where', {
      position: {
        value: { job_id: this.pinState.job()?.id as number },
        operator: 'scope',
      },
    });

    return query.getQuery();
  }

  
}
